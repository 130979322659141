import axios from 'axios';
import * as auth0Utils from '@utils/auth0';
import {
  Areal,
  ErrorResponse,
  GenerellInformasjon,
  LATEST_MODEL_VERSION,
  Part,
  StandalonePartTypes,
  TekniskVerdi,
  Tilstandsrapport,
} from '@supertakst/model-common';
import { onlineManager } from '@tanstack/react-query';
import {
  deleteTilstandsrapport,
  getOngoingTilstandsrapporter,
  getTilstandsrapport,
  setTilstandsrapport,
  TilstandsrapportWithDirty,
} from '@utils/localDb';
import { hasTmpId, isTmpId, newTilstandsrapport, NewTilstandsrapportData } from '@utils/modelUtils';
import LogRocket from 'logrocket';
import { Conflict } from '@store/atoms';
import { get } from 'idb-keyval';

const client = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_BASE_URL,
  timeout: 20000,
});
client.interceptors.request.use(async (reqConfig) => {
  const accessToken = await auth0Utils.getTokenSilently();
  const onBehalfOf = await get('onBehalfOf');
  return {
    ...reqConfig,
    headers: {
      ...reqConfig.headers,
      Authorization: `Bearer ${accessToken}`,
      ...(onBehalfOf ? { 'Supertakst-OnBehalfOfUid': onBehalfOf } : null),
    },
  };
});

export const createNewTilstandsrapport = async (
  data: NewTilstandsrapportData
): Promise<Tilstandsrapport> => {
  const newReport = newTilstandsrapport(data);
  if (onlineManager.isOnline()) {
    try {
      const { data } = await client.post(`/v${LATEST_MODEL_VERSION}/tilstandsrapport`, newReport);
      await setTilstandsrapport(data);
      return data;
    } catch (err) {
      await setTilstandsrapport({
        ...newReport,
        dirty: true,
        updatedAt: new Date(),
      });
      if (err.response?.status !== 401) {
        LogRocket.captureException(err, { tags: { action: 'createNewTilstandsrapport' } });
      }
      throw err;
    }
  } else {
    await setTilstandsrapport({ ...newReport, dirty: true });
    return newReport;
  }
};

export const duplicateTilstandsrapport = async (
  tilstandsrapport: Tilstandsrapport
): Promise<Tilstandsrapport> => {
  if (onlineManager.isOnline()) {
    try {
      const { data } = await client.post(
        `/v${tilstandsrapport.modelVersion}/tilstandsrapport`,
        tilstandsrapport
      );
      await setTilstandsrapport(data);
      return data;
    } catch (err) {
      LogRocket.captureException(err, { tags: { action: 'duplicateTilstandsrapport' } });
      throw err;
    }
  } else {
    const data = { ...tilstandsrapport, dirty: true };
    await setTilstandsrapport(data);
    return data;
  }
};

export const fetchExists = async (tilstandsrapportIds: Array<number>) => {
  const { data } = await client.post<Array<{ id: number; exist: boolean }>>(
    `/tilstandsrapport/exists`,
    { ids: tilstandsrapportIds }
  );
  return data;
};

export const syncLocalTilstandsrapporter = async (): Promise<Conflict[]> => {
  const localStoredNonDirty = Object.values(await getOngoingTilstandsrapporter()).filter(
    (t) => !hasTmpId(t) && !t.dirty
  );
  const serverExists = await fetchExists(localStoredNonDirty.map((t) => t.id));
  await Promise.all(
    serverExists
      .filter((e) => !e.exist)
      .map(async (e) => {
        await deleteTilstandsrapport(e.id);
        console.log(
          `Deleted local non dirty tilstandsrapport not known to server`,
          localStoredNonDirty[e.id]
        );
      })
  );

  const localTilstandsrapporter = await getOngoingTilstandsrapporter();
  const currentlyWorkingOn = await get('currentTilstandsrapportId');
  return (
    await Promise.all(
      Object.values(localTilstandsrapporter)
        .filter((tilstandsrapport) => {
          // Filter out local tilstandsrapport that are not synced ever.
          return tilstandsrapport.id !== currentlyWorkingOn;
        })
        .map(async (tilstandsrapport): Promise<Conflict | undefined> => {
          if (tilstandsrapport.dirty === true) {
            if (onlineManager.isOnline()) {
              try {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { dirty, ...updateData } = tilstandsrapport;
                if (hasTmpId(tilstandsrapport)) {
                  try {
                    const { data } = await client.post<Tilstandsrapport>(
                      `v${tilstandsrapport.modelVersion}/tilstandsrapport`,
                      updateData
                    );
                    await setTilstandsrapport(data);
                    await deleteTilstandsrapport(tilstandsrapport.id);
                    console.log(
                      `Synchronized unsaved tilstandsrapport to server (${tilstandsrapport.id}=>${data.id})`,
                      data
                    );
                  } catch (err) {
                    LogRocket.captureException(err, {
                      tags: {
                        action: 'syncLocalTilstandsrapporter unsaved',
                        tilstandsrapportId: tilstandsrapport.id,
                      },
                    });
                    // noinspection ExceptionCaughtLocallyJS
                    throw err;
                  }
                } else {
                  try {
                    const { data } = await client.put<Tilstandsrapport>(
                      `v${tilstandsrapport.modelVersion}/tilstandsrapport/${tilstandsrapport.id}`,
                      updateData
                    );
                    console.log(`Synchronized changed tilstandsrapport to server`, data);
                    await setTilstandsrapport(data);
                  } catch (err) {
                    LogRocket.captureException(err, {
                      tags: {
                        action: 'syncLocalTilstandsrapporter changed',
                        tilstandsrapportId: tilstandsrapport.id,
                      },
                    });
                    // noinspection ExceptionCaughtLocallyJS
                    throw err;
                  }
                }
              } catch (err) {
                if (err.response?.status === 409) {
                  const response = err.response.data as ErrorResponse;
                  return {
                    serverVersion: response.error[0].context.serverVersion as Tilstandsrapport,
                    updateFromApi: response.error[0].context.updateFromApi as Tilstandsrapport,
                  };
                }
              }
            } else {
              console.log(
                'found dirty local tilstandsrapport but not online to sync it!',
                tilstandsrapport
              );
            }
          }
        })
    )
  ).filter(Boolean) as Conflict[];
};

export type OnGoingTilstandsrapporterResponse = {
  tilstandsrapporter: Tilstandsrapport[];
  conflicts: Conflict[];
};

export const fetchOngoingTilstandsrapporter =
  async (): Promise<OnGoingTilstandsrapporterResponse> => {
    const conflicts: Conflict[] = [];
    let tilstandsrapporterFromServer: {
      knownReports: Array<Tilstandsrapport>;
      newReports: Array<Tilstandsrapport>;
    } = { knownReports: [], newReports: [] };

    const localTilstandsrapporter = Object.values(await getOngoingTilstandsrapporter());
    const syncedLocalReports = localTilstandsrapporter.filter((t) => !hasTmpId(t) && !t.dirty);

    if (onlineManager.isOnline()) {
      const currentlyWorkingOn = await get('currentTilstandsrapportId');
      const syncedLocalIds = syncedLocalReports.map((t) => t.id);
      console.log('syncedLocalIds', syncedLocalIds);
      const { data } = await client.get(`/tilstandsrapport/ongoing`, {
        params: {
          ...(syncedLocalIds.length ? { knownIds: syncedLocalIds.join(',') } : null),
        },
      });
      tilstandsrapporterFromServer = data;

      // Delete local non-dirty tilstandsrapporter unknown to server
      await Promise.all(
        Object.values(syncedLocalReports)
          .filter(
            (lr) =>
              tilstandsrapporterFromServer.knownReports.find((sr) => sr.id === lr.id) === undefined
          )
          .map(async (lr) => {
            if (lr.id !== currentlyWorkingOn) {
              await deleteTilstandsrapport(lr.id);
              console.log(`Deleted local tilstandsrapport unknown to server (${lr.id})`);
            }
          })
      );
    }

    const dirtyTilstandsrapportLog = Object.values(localTilstandsrapporter).filter(
      (lr) => lr.dirty
    );
    if (dirtyTilstandsrapportLog && dirtyTilstandsrapportLog.length) {
      console.log('Got dirty tilstandsrapport from local', dirtyTilstandsrapportLog);
    }

    await Promise.all(
      Object.values(await getOngoingTilstandsrapporter()).map(
        async (localReport: TilstandsrapportWithDirty) => {
          const serverReport = [
            ...tilstandsrapporterFromServer.knownReports,
            ...tilstandsrapporterFromServer.newReports,
          ].find((t) => t.id === localReport.id);
          const currentlyWorkingOn = await get('currentTilstandsrapportId');
          if (serverReport) {
            if (!localReport.dirty && localReport.id !== currentlyWorkingOn) {
              await setTilstandsrapport(serverReport);
              console.log(
                `Updated local tilstandsrapport with updates from server`,
                localReport,
                serverReport
              );
              return serverReport;
            } else if (
              localReport.dirty &&
              localReport.updateHistory?.length !== serverReport.updateHistory?.length
            ) {
              if (serverReport.id !== currentlyWorkingOn) {
                conflicts.push({
                  serverVersion: serverReport,
                  updateFromApi: localReport,
                });
              }
            }
          }
          return localReport;
        }
      )
    );

    // Store new tilstandsrapporter from server
    await Promise.all(
      tilstandsrapporterFromServer.newReports.map(async (t) => {
        if (!(await getTilstandsrapport(t.id))) {
          await setTilstandsrapport(t);
          console.log(`Stored new tilstandsrapport from server`, JSON.stringify(t));
          return t;
        }
      })
    );

    // TODO find a way to remove non temp reports from localstorage (deleted on server)
    return {
      tilstandsrapporter: Object.values(await getOngoingTilstandsrapporter()).sort((a, b) =>
        (typeof b.updatedAt === 'string' ? b.updatedAt : b.updatedAt.toISOString()).localeCompare(
          typeof a.updatedAt === 'string' ? a.updatedAt : a.updatedAt.toISOString()
        )
      ),
      conflicts: conflicts,
    };
  };

export const fetchCompletedTilstandsrapporter = async (): Promise<Tilstandsrapport[]> => {
  if (!onlineManager.isOnline()) {
    return [];
  }
  const { data } = await client.get('/tilstandsrapport?submitted=true');
  return data;
};

export const searchTilstandsrapporter = async (
  search: string,
  submitted: boolean = false
): Promise<Tilstandsrapport[]> => {
  if (!onlineManager.isOnline()) {
    return [];
  }
  const { data } = await client.get(`/tilstandsrapport/search?q=${search}&submitted=${submitted}`);
  return data;
};

export const fetchTilstandsrapport = async (id: number): Promise<Tilstandsrapport | undefined> => {
  if (onlineManager.isOnline() && !isTmpId(id)) {
    const localVersion = await getTilstandsrapport(id);
    if (localVersion?.dirty) {
      return localVersion;
    } else {
      const { data } = await client.get<Tilstandsrapport>(`/tilstandsrapport/${id}`);
      await setTilstandsrapport(data);
      return data;
    }
  } else {
    return await getTilstandsrapport(id);
  }
};

export const savePart = async (
  id: number,
  partId: number,
  values: Part | GenerellInformasjon | Areal | TekniskVerdi
): Promise<void> => {
  const tilstandsrapport = await getTilstandsrapport(id);
  if (!tilstandsrapport) {
    throw new Error('Fant ikke tilstandsrapport i save');
  }

  const standaloneMatch = Object.keys(StandalonePartTypes).find(
    (k) => StandalonePartTypes[k].url === partId
  );
  let updatedTilstandsrapportData: Tilstandsrapport;
  if (standaloneMatch) {
    const standalonePartId = StandalonePartTypes[standaloneMatch as string].id;
    updatedTilstandsrapportData = {
      ...tilstandsrapport,
      [standalonePartId]: values,
    } as Tilstandsrapport;
  } else {
    updatedTilstandsrapportData = {
      ...tilstandsrapport,
      parts: tilstandsrapport.parts.map((part) => {
        if (part.id === partId) {
          return values;
        }
        return part;
      }),
    } as Tilstandsrapport;
  }

  await saveTilstandsrapport(id, updatedTilstandsrapportData, false);
};

export const saveTilstandsrapport = async (
  id: number,
  tilstandsrapportData: Tilstandsrapport,
  overrideVersionConflict
) => {
  if (onlineManager.isOnline()) {
    if (hasTmpId(tilstandsrapportData)) {
      try {
        const tmpId = tilstandsrapportData.id;
        const { data } = await client.post<Tilstandsrapport>(
          `v${tilstandsrapportData.modelVersion}/tilstandsrapport`,
          { ...tilstandsrapportData, overrideVersionConflict }
        );
        await setTilstandsrapport(data);
        await deleteTilstandsrapport(tmpId);
        window.location.replace(`/tilstandsrapport/${data.modelVersion}/${data.id}`);
      } catch (err) {
        const error = err ?? new Error('Unknown error during create');
        await setTilstandsrapport({
          ...tilstandsrapportData,
          dirty: true,
          updatedAt: new Date(),
        });
        if (error && error.response?.status !== 401) {
          LogRocket.captureException(error, {
            tags: { action: 'saveTilstandsrapport new', tilstandsrapportId: id },
          });
        }
        throw error;
      }
    } else {
      try {
        // Store changes to localStorage before sending to server
        await setTilstandsrapport({
          ...tilstandsrapportData,
          dirty: true,
          updatedAt: new Date(),
        });

        const { data } = await client.put<Tilstandsrapport>(
          `v${tilstandsrapportData.modelVersion}/tilstandsrapport/${id}`,
          { ...tilstandsrapportData, overrideVersionConflict }
        );
        await setTilstandsrapport(data);
        return data;
      } catch (err) {
        const error = err ?? new Error('Unknown error on save');
        if (error && error.response?.status !== 401) {
          LogRocket.captureException(error, {
            tags: { action: 'saveTilstandsrapport update', tilstandsrapportId: id },
          });
        }
        throw error;
      }
    }
  } else {
    const updatedLocalTilstandsrapport = {
      ...tilstandsrapportData,
      dirty: true,
      updatedAt: new Date(),
    };
    await setTilstandsrapport(updatedLocalTilstandsrapport);
    return updatedLocalTilstandsrapport;
  }
};

export const submitTilstandsrapport = async (
  id: number,
  tilstandsrapportData: Tilstandsrapport
) => {
  try {
    const { data } = await client.put<Tilstandsrapport>(
      `v${tilstandsrapportData.modelVersion}/tilstandsrapport/${id}/submit`,
      tilstandsrapportData
    );
    // Delete from localstorage
    await deleteTilstandsrapport(id);
    return data;
  } catch (err) {
    LogRocket.captureException(err, {
      tags: { action: 'submitTilstandsrapport', tilstandsrapportId: id },
    });
    throw err;
  }
};

export const archiveTilstandsrapport = async (id: number) => {
  if (onlineManager.isOnline() && !isTmpId(id)) {
    await client.delete(`/tilstandsrapport/${id}`);
  }
  await deleteTilstandsrapport(id);
};

export const uploadPdfReportToSupertakst = async (id: number) => {
  if (onlineManager.isOnline() && !isTmpId(id)) {
    await client.put(`/tilstandsrapport/${id}/pdf/upload`);
  }
};
